import { Box, Typography } from '@mui/material'
import React from 'react'
import "../../styles/FooterStyles.css"
import { Colors } from "../../styles/theme/Colors"

const Footer = () => {
  return (
    <>
        <Box sx={{textAlign: "center", padding: {lg:"70px 0 30px 0", sm:"50px 0 50px 0", xs:"50px 0 50px 0"},}}  className="footer-box">
            <Typography className="footer-policy font-size-14 fw-400 mb-15"> Privacy Policy    |    Terms of use</Typography>
            <Box className="footer-copyright font-size-13 fw-400">Copyright &copy; 2023 <Box sx={{ color: Colors.secondary, display: 'inline' }}>Pilot Project</Box>. All rights reserved.</Box>
        </Box>
    </>
  )
}

export default Footer