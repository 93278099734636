import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import { getStateDistrictList, getStateandDistrict } from "../services/APIService";
import Select from "react-select";

const header = [
  {
    id: "states",
    label: "States",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "districts",
    label: "Districts",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

// const data = [
//   { id: 1, states: "All", districts: "All" },
//   { id: 2, states: "Andaman and Nicobar", districts: "Nicobar" },
//   {
//     id: 3,
//     states: "Andaman and Nicobar",
//     districts: "North and Middle Andaman",
//   },
//   { id: 4, states: "Andaman and Nicobar", districts: "South Andaman Nicobar" },
//   { id: 5, states: "Andhra Pradesh", districts: "Alluri Sitharama Raju" },
//   { id: 6, states: "Andhra Pradesh", districts: "Anakapalli" },
//   { id: 7, states: "Andhra Pradesh", districts: "Ananthapuramu" },
// ];
// const data = [];
// const data = getStateDistrictList().then((response) => {
//   return response;
// });

const StateDistricts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState("All");
  const [district, setDistrict] = useState("All");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    setFilteredData(
      state === "All"
        ? filteredData
        : filteredData.filter((dt) => dt.states === state)
    );
  }, [filteredData, state]);

  useEffect(() => {
    setFilteredData(
      district === "All"
        ? filteredData
        : filteredData.filter((dt) => dt.districts === district)
    );
  }, [filteredData, district]);

  useEffect(() => {
    getStateDistrictList().then((response) => {
      setData(response.state_districts);
      setFilteredData(response.state_districts);
    });
    getStateandDistrict().then((response) => {
      setDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setStates([{ value: "0", label: "All" }, ...response.states]);
    });
  }, []);

  const stateChange = (selectedOption) => {
    setFilteredData(data);
    setState(selectedOption.label);
  };

  const districtChange = (selectedOption) => {
    setFilteredData(data);
    setDistrict(selectedOption.label);
  };

  console.log('filteredData', filteredData)
  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width stateDistFilter"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '50px',
                      boxShadow: '0px 0px 15px rgba(228, 236, 255, 0.74)',
                      color: '#65698a80',
                      borderColor: 'transparent'
                    }),
                  }}
                  options={states}
                  onChange={stateChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: '50px',
                      boxShadow: '0px 0px 15px rgba(228, 236, 255, 0.74)',
                      color: '#65698a80',
                      borderColor: 'transparent'
                    }),
                  }}
                  options={districts}
                  onChange={districtChange}
                />
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default StateDistricts;
