export const Colors = {
  primary: "#4F46BA",
  secondary: "#F06000",
  logo: "#013364",
  white: "#FFFFFF",
  divider: '#65698A',
  tableHeader: "#EFF3FC",
  tableEvenRow: "#F9FBFF",
  tableBorder: "#DAE4FA",
  backgroundColor: "#E8EEFE",
  lightGrey: "#E4ECFF"
};

export default Colors;
