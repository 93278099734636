import React from "react";
import Layout from "../components/Layout/Layout";
import background from "../images/background.png";
import AccordionComp from "../components/AccordionComp";
import { Box } from "@mui/material";
import "../styles/HelpStyles.css"

const Help = () => {
  return (
    <Layout backGroundImage={background}>
      <Box sx={{ width: "70%" }} className="help-center-main">
        <h6 className="font-size-18 fw-400 mb-20">Help Centre</h6>
        <p className="font-size-14">
          Everything you need to know about this project. Can't find the answer
          you are looking for? Please email us or call us
        </p>
        <div className="faq-box mt-40">
          <AccordionComp
            typography="Introduction"
            accordionDetails="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget."
            panelNumber="1"
          />
          <AccordionComp
            typography="How to use this website"
            accordionDetails="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget."
            panelNumber="2"
          />
          <AccordionComp
            typography="Pilot Profile"
            accordionDetails="Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae."
            panelNumber="3"
          />
          <AccordionComp
            typography="Rating Calculation"
            accordionDetails="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget."
            panelNumber="4"
          />
        </div>
      </Box>
    </Layout>
  );
};

export default Help;
