import { useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useEffect, useState } from "react";
import { getEventbyEventId } from "../services/APIService";
import { Box, Grid } from "@mui/material";
import Advertisement from "../components/Advertisement";
import DataTable from "../components/DataTable";

const PilotProfile = () => {
  const { event_id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [totalRounds, setTotalRounds] = useState(0);

  const header = [
    {
      id: "id",
      label: "#",
      width: 130,
      numeric: true,
      disablePadding: false,
    },
    {
      id: "gender",
      label: "Gender",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "natioanlity",
      label: "Natioanlity",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "first_name",
      label: "First Name",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "last_name",
      label: "Last Name",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "rating",
      label: "Rating",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
    {
      id: "emp_id",
      label: "Emp ID",
      width: 130,
      numeric: false,
      disablePadding: false,
    },
  ];

  useEffect(() => {
    getEventbyEventId(event_id).then((event) => {
      setEventData(event.eventData);
      setTotalRounds(event.eventData.total_rounds);
    });
  }, [event_id]);

  if (totalRounds > 0) {
    for (var i = 1; i <= totalRounds; i++) {
      var round = {
        id: "r" + i,
        label: "R" + i,
        width: 130,
        numeric: true,
        disablePadding: false,
      };
      header.push(round);
    }
  }

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement />
          </Grid>
          <Grid item xs={10} sm={10}>
            <Box sx={{ display: "table" }}>
              <Box sx={{ display: "table-cell", textAlign: "left" }}>
                Event: {eventData.name}
              </Box>
              <Box sx={{ display: "table-cell", textAlign: "right" }}>
                Date: {eventData.start_date}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PilotProfile;
