import React from "react";
import Layout from "../components/Layout/Layout";
import Box from "@mui/material/Box";
import "../styles/PilotStyles.css";
import Search from "../components/Search"
import background from "../images/background.png";

const Pilot = () => {
  return (
    <Layout backGroundImage={background}>
      <Box className="pilot">
        <Search/>
      </Box>
    </Layout>
  );
};

export default Pilot;
