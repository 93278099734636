import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL || `http://127.0.0.1:8000`;

export async function getAllPilots() {
  try {
    const response = await axios.get(baseUrl + "/get-pilots");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getPilotByEmpId(emp_id) {
  try {
    const response = await axios.get(baseUrl + "/get-pilot-by-emp-id", {
      params: {
        emp_id: emp_id,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getEventResultsByEmpId(emp_id, offset, perPage) {
  try {
    const response = await axios.get(baseUrl + "/get-event-results-by-emp-id", {
      params: {
        emp_id: emp_id,
        offset: offset,
        per_page: perPage,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getStateDistrictList() {
  try {
    const response = await axios.get(baseUrl + "/get-state-district-list");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getEventsList() {
  try {
    const response = await axios.get(baseUrl + "/get-events-list");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getStateandDistrict() {
  try {
    const response = await axios.get(baseUrl + "/get-state-and-district");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getEventbyEventId(event_id) {
  try {
    const response = await axios.get(baseUrl + "/get-event-by-event-id", {
      params: {
        event_id: event_id,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getTopPerformers() {
  try {
    const response = await axios.get(baseUrl + "/get-top-performers");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getPilotMonthlyResults(
  emp_id,
  eventType,
  eventRange,
  page,
  perPage
) {
  try {
    const response = await axios.get(baseUrl + "/get-pilot-monthly-results", {
      params: {
        emp_id: emp_id,
        page: page,
        event_type: eventType,
        event_range: eventRange,
        per_page: perPage,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getDistrictByStateId(stateId) {
  try {
    const response = await axios.get(baseUrl + "/get-district-by-state-id", {
      params: {
        state_id: stateId,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getPilotsByStateDistrict(
  stateId,
  districtId,
  page,
  perPage
) {
  try {
    const response = await axios.get(
      baseUrl + "/get-pilots-by-state-district",
      {
        params: {
          state_id: stateId,
          district_id: districtId,
          page: page,
          per_page: perPage,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
}
