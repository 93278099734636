import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Colors from "../../styles/theme/Colors";
import { visuallyHidden } from "@mui/utils";
import MaleIcon from "../../images/icon-male.svg";
import FemaleIcon from "../../images/icon-female.svg";
import Icon from "@mui/material/Icon";
import data from "../common/data.json";
import { Link } from "react-router-dom";

// const rows = [
//   {
//       "name": "Cupcake",
//       "calories": 305,
//       "fat": 3.7,
//       "carbs": 67,
//       "protein": 4.3
//   },
//   {
//       "name": "Donut",
//       "calories": 452,
//       "fat": 25,
//       "carbs": 51,
//       "protein": 4.9
//   },
//   {
//       "name": "Eclair",
//       "calories": 262,
//       "fat": 16,
//       "carbs": 24,
//       "protein": 6
//   },
//   {
//       "name": "Frozen yoghurt",
//       "calories": 159,
//       "fat": 6,
//       "carbs": 24,
//       "protein": 4
//   },
//   {
//       "name": "Gingerbread",
//       "calories": 356,
//       "fat": 16,
//       "carbs": 49,
//       "protein": 3.9
//   },
//   {
//       "name": "Honeycomb",
//       "calories": 408,
//       "fat": 3.2,
//       "carbs": 87,
//       "protein": 6.5
//   },
//   {
//       "name": "Ice cream sandwich",
//       "calories": 237,
//       "fat": 9,
//       "carbs": 37,
//       "protein": 4.3
//   },
//   {
//       "name": "Jelly Bean",
//       "calories": 375,
//       "fat": 0,
//       "carbs": 94,
//       "protein": 0
//   },
//   {
//       "name": "KitKat",
//       "calories": 518,
//       "fat": 26,
//       "carbs": 65,
//       "protein": 7
//   },
//   {
//       "name": "Lollipop",
//       "calories": 392,
//       "fat": 0.2,
//       "carbs": 98,
//       "protein": 0
//   },
//   {
//       "name": "Marshmallow",
//       "calories": 318,
//       "fat": 0,
//       "carbs": 81,
//       "protein": 2
//   },
//   {
//       "name": "Nougat",
//       "calories": 360,
//       "fat": 19,
//       "carbs": 9,
//       "protein": 37
//   },
//   {
//       "name": "Oreo",
//       "calories": 437,
//       "fat": 18,
//       "carbs": 63,
//       "protein": 4
//   }
// ];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.tableHeader,
    color: Colors.divider,
    borderRight: "1px solid #DAE4FA",
  },
  [`&.${tableCellClasses.root}`]: {
    // fontSize: 14,
    borderRight: "1px solid #DAE4FA",
  },
  // [`& .${tableCellClasses.root}`]: {
  //   border: "44px solid #DAE4FA"
  // }
  color: Colors.divider,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: Colors.tableEvenRow,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: Colors.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
  "&:hover tr": {
    backgroundColor: Colors.tableEvenRow,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  // console.log('array', array)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// const headCells = [
//   {
//     id: 'name',
//     numeric: false,
//     disablePadding: true,
//     label: 'Dessert (100g serving)',
//   },
//   {
//     id: 'calories',
//     numeric: true,
//     disablePadding: false,
//     label: 'Calories',
//   },
//   {
//     id: 'fat',
//     numeric: true,
//     disablePadding: false,
//     label: 'Fat (g)',
//   },
//   {
//     id: 'carbs',
//     numeric: true,
//     disablePadding: false,
//     label: 'Carbs (g)',
//   },
//   {
//     id: 'protein',
//     numeric: true,
//     disablePadding: false,
//     label: 'Protein (g)',
//   },
// ];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ borderBottom: "3px solid " + Colors.tableBorder }}>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:first-of-type": { borderTopLeftRadius: "7px" },
              "&:last-child": { borderTopRightRadius: "7px" },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ header, rows, pageName = "" }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(header[0]["id"]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (page > 0 && rows.length < rowsPerPage) {
    setPage(0);
  }

  const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
    // console.log('visibleRows', visibleRows)
    // console.log('header', header)
  return (
    <Box sx={{ backgroundColor: "transparent" }} className="mt-40">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={header}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    {header.map((col) =>
                      col.id === "gender" ? (
                        row[col.id] === "male" ? (
                          <StyledTableCell
                            key={col.id + "_" + row.id}
                            align="center"
                          >
                            <Icon>
                              <img src={MaleIcon} alt="MaleIcon" />
                            </Icon>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell
                            key={col.id + "_" + row.id}
                            align="center"
                          >
                            <Icon>
                              <img src={FemaleIcon} alt="FemaleIcon" />
                            </Icon>
                          </StyledTableCell>
                        )
                      ) : col.id === "type" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          {data.type[row[col.id]]}
                        </StyledTableCell>
                      ) : col.id === "range" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          {data.range[row[col.id]]}
                        </StyledTableCell>
                      ) : col.id === "name" && pageName === "events" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          <Link
                            to={{ pathname: "/event/" + row.event_id }}
                            key={row.id}
                            className="link"
                          >
                            {row[col.id]}
                          </Link>
                        </StyledTableCell>
                      ) : col.id === "membership_id" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                          className={
                            data.membership[row[col.id]].toLowerCase() +
                            "-membr"
                          }
                        >
                          {data.membership[row[col.id]]}
                        </StyledTableCell>
                      ) : col.id === "emp_id" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          <Link
                            to={{ pathname: "/profile/" + row[col.id] }}
                            key={row.id}
                            className="link"
                          >
                            {row[col.id]}
                          </Link>
                        </StyledTableCell>
                      ) : col.id === "is_leader" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          {data.is_leader[row[col.id]]}
                        </StyledTableCell>
                      ) : col.id === "states" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          <Link
                            to={{ pathname: "/pilots/" + row.state_id }}
                            key={row.id}
                            className="link"
                          >
                            {row[col.id]}
                          </Link>
                        </StyledTableCell>
                      ) : col.id === "districts" ? (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          <Link
                            to={{ pathname: "/pilots/" + row.state_id + "/" + row.id }}
                            key={row.id}
                            className="link"
                          >
                            {row[col.id]}
                          </Link>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          key={col.id + "_" + row.id}
                          align="center"
                        >
                          {row[col.id]}
                        </StyledTableCell>
                      )
                    )}
                  </StyledTableRow>
                );
              })}
              {(emptyRows > 0 || visibleRows.length === 0) && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={header.length} align="center">No data found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page > 0 && rows.length < rowsPerPage ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
