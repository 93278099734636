import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "../../styles/AdvertisementStyles.css";
import Colors from "../../styles/theme/Colors";
import Grid from '@mui/material/Grid';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: Colors.tableHeader,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: "18px",
  minHeight: "676px",
  display: 'flex',
  alignItems: 'center',
  color: Colors.divider,
  justifyContent: 'center'
}));

function Advertisement() {
  return (
    <Grid item md={11} display={{ sm: "none", md: "block" }}>
      <Item elevation={0}>Advertisement area</Item>
    </Grid>
  );
}

export default Advertisement;
