import Layout from "../components/Layout/Layout";
import { Box, Grid } from "@mui/material";
import Advertisement from "../components/Advertisement";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import {
  getPilotByEmpId,
  getPilotMonthlyResults,
} from "../services/APIService";
import { useParams } from "react-router-dom";
import data from "../components/common/data.json";
import DataTable from "../components/DataTable";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Colors from "../styles/theme/Colors";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const header = [
  {
    id: "date",
    label: "Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "is_leader",
    label: "Leader/Follower",
    width: 50,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "score",
    label: "Score",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "rating_inc_dec",
    label: "Rating +/-",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "rating",
    label: "Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "partner",
    label: "Partner",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "partner_rating",
    label: "Partner Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "partner_emp_id",
    label: "Partner Emp ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "partner_nationality",
    label: "Partner Nationality",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
];

const PilotResults = () => {
  const { emp_id } = useParams();

  const [pilotData, setPilotData] = useState([]);
  const [pilotMembership, setpilotMembership] = useState([]);
  const [pilotNationality, setpilotNationality] = useState([]);
  const [pilotState, setpilotState] = useState([]);
  const [pilotDistrict, setpilotDistrict] = useState([]);
  const [pilotMonthlyResult, setPilotMonthlyResult] = useState([]);
  const [lastPage, setlastPage] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [eventType, setEventType] = useState("0");
  const [eventRange, setEventRange] = useState("0");
  const perPage = 10;
  const [lastResult, setLastResult] = useState(false);

  useEffect(() => {
    getPilotByEmpId(emp_id).then((pilot) => {
      setPilotData(pilot.pilotData);
      setpilotMembership(pilot.membership);
      setpilotNationality(pilot.nationality);
      setpilotState(pilot.state);
      setpilotDistrict(pilot.district);
    });

    console.log("prevPage", prevPage);
    console.log("currentPage", currentPage);
    if (
      (!lastResult && prevPage !== currentPage) ||
      eventType > 0 ||
      eventRange > 0
    ) {
      fetchResults();
    }
  }, [emp_id, currentPage, eventType, eventRange, lastResult]);

  function handleTypeChange(newType) {
    setEventType(newType);
    setcurrentPage(1); // Reset the current page to 1
    setPrevPage(0);
    setLastResult(false);
  }

  function handleRangeChange(newRange) {
    setEventRange(newRange);
    setcurrentPage(1); // Reset the current page to 1
    setPrevPage(0);
    setLastResult(false);
  }

  function fetchResults() {
    getPilotMonthlyResults(
      emp_id,
      eventType,
      eventRange,
      currentPage,
      perPage
    ).then((results) => {
      if (results) {
        if (currentPage === 1) {
          setPilotMonthlyResult(results.event_pilot_results);
        } else {
          setPilotMonthlyResult((pilotMonthlyResult) => [
            ...pilotMonthlyResult,
            ...results.event_pilot_results,
          ]);
          // var joined = pilotMonthlyResult.concat(results.event_pilot_results);
          // setPilotMonthlyResult(joined);
        }
        setPrevPage(currentPage);

        setlastPage(results.pagination.last_page);
        console.log("currentPage", currentPage);
        if (results.pagination.last_page === currentPage) {
          setLastResult(true);
          console.log("lastResult", lastResult);
        }
      }
    });
  }

  useEffect(() => {
    // Attach event listener to the scrollable container
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        !lastResult &&
        pilotMonthlyResult.length > 0
      ) {
        // Load more data when the user has scrolled to near the bottom of the container
        setcurrentPage((prevPage) => prevPage + 1);
      }
    };
    console.log("lastResult", lastResult);
    console.log("pilotMonthlyResult.length", pilotMonthlyResult.length);
    // Add event listener to the scrollable container
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pilotMonthlyResult, lastResult]);

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={10} sm={10} md={8}>
            <Paper
              sx={{
                p: 2,
                flexGrow: 1,
                borderRadius: "7px",
                boxShadow: "0px 0px 15px 0px rgba(228, 236, 255, 0.74);",
                marginBottom: "0px",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={1} className="profile-table">
                <Grid item xs={12}>
                  <Box className="float-right fw-400 font-size-15 status-info mb-15">
                    Status:{" "}
                    <span className="active-state font-size-15 fw-600">
                      {data.status[pilotData.status]}
                    </span>{" "}
                  </Box>
                </Grid>

                <Grid item xs={2} sm={2} className="pt-0">
                  <Box className="pilotDpBlock">
                    <Img
                      alt="complex"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKMjeeornJdOe6FD8JTzqih-CByVmSWpSD0g&usqp=CAU"
                    />
                  </Box>
                  <h4 className="fw-700 font-size-15 label-color text-uppercase text-center">
                    {pilotData.name}
                  </h4>
                </Grid>
                <Grid item xs={9} sm={9} className="profile-data-block">
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Emp ID
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box emp-id"
                    >
                      {pilotData.emp_id}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Int ID
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box int-id"
                    >
                      {pilotData.int_id}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Title
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.title}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Membership
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={2}
                      className={`profile-data profile-box ${
                        pilotMembership.name
                          ? pilotMembership.name.toLowerCase()
                          : ""
                      }-membr`}
                    >
                      {pilotMembership.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Gender
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.gender}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Year of Birth
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotData.yob}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} className="profile-data-row">
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      Nationality
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotNationality.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      State
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotState.name}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-labels profile-box"
                    >
                      District
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="profile-data profile-box"
                    >
                      {pilotDistrict.name}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid className="result-filter-main" item xs={12} sm={12} md={12}>
              <Grid
                container
                spacing={2}
                className="justifyContentBetween custom-select-width custom-pl result-filter-inner"
              >
                <Grid item xs={6} sm={6} lg={2}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={eventType}
                    onChange={(e) => handleTypeChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="0"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      value="1"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="Flight"
                    />
                    <FormControlLabel
                      value="2"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="Simulation"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6} sm={6} lg={2}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={eventRange}
                    onChange={(e) => handleRangeChange(e.target.value)}
                  >
                    <FormControlLabel
                      value="0"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      value="3"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="Long Flight"
                    />
                    <FormControlLabel
                      value="2"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="Medium Range"
                    />
                    <FormControlLabel
                      value="1"
                      className="radio-button"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: Colors.secondary,
                            },
                          }}
                        />
                      }
                      label="Short Flight"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
            {pilotMonthlyResult.map((monthlyResult, index) => (
              <div className="result-block" key={index}>
                <h1>{monthlyResult.month}</h1>
                <DataTable header={header} rows={monthlyResult.data} />
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PilotResults;
