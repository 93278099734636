import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import Select from "@mui/material/Select";
import Select from "react-select";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Colors from "../styles/theme/Colors";
import FormHelperText from "@mui/material/FormHelperText";
import { getStateandDistrict, getTopPerformers } from "../services/APIService";

const header = [
  {
    id: "id",
    label: "#",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "membership_id",
    label: "Membership",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "gender",
    label: "Gender",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "nationality_name",
    label: "Nationality",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "title",
    label: "Title",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "name",
    label: "Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "rating",
    label: "Rating",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "state_name",
    label: "State",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "district_name",
    label: "District",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "emp_id",
    label: "EMP ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "int_id",
    label: "INT ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

// const data = [
//   {
//     id: 1,
//     membership: "Gold",
//     gender: 1,
//     nationality: "Indian",
//     title: "Captain",
//     first_name: "Suresh",
//     last_name: "Naik",
//     rating: 2100,
//     state: "Goa",
//     district: "North Goa",
//     emp_id: " 00192475MH2023",
//     int_id: "21064822",
//     event_type: "simulation",
//     range: "long_flight",
//     age: 28,
//   },
//   {
//     id: 2,
//     membership: "Silver",
//     gender: 2,
//     nationality: "Indian",
//     title: "Captain",
//     first_name: "Peter",
//     last_name: "Dulay",
//     rating: 1900,
//     state: "Kolkata",
//     district: "Babughat",
//     emp_id: " 00192485MH2023",
//     int_id: "21064823",
//     event_type: "flight",
//     range: "medium_range",
//     age: 18,
//   },
//   {
//     id: 3,
//     membership: "Bronze",
//     gender: 1,
//     nationality: "Indian",
//     title: "Captain",
//     first_name: "Vikrant",
//     last_name: "Thakur",
//     rating: 2000,
//     state: "Andaman and Nicobar",
//     district: "Nicobar",
//     emp_id: " 00192486MH2023",
//     int_id: "21064824",
//     event_type: "flight",
//     range: "short_flight",
//     age: 15,
//   },
// ];
const genders = [
  { value: "all", label: "All" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const ages = [
  { value: 0, label: "All" },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: "Above 18" },
];

const TopPerformers = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState(0);
  const [district, setDistrict] = useState(0);
  const [eventType, setEventType] = useState("0");
  const [range, setRange] = useState("0");
  const [gender, setGender] = useState("all");
  const [age, setAge] = useState(0);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    getTopPerformers().then((response) => {
      setData(response.top_pilots);
    });
    getStateandDistrict().then((response) => {
      setDistricts([{ value: 0, label: "All" }, ...response.districts]);
      setStates([{ value: 0, label: "All" }, ...response.states]);
    });
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    console.log("useEffect state", state);
    setFilteredData(
      data.filter(
        (dt) =>
          (eventType === "0" || dt.type.includes(parseInt(eventType, 10))) &&
          (range === "0" || dt.range.includes(parseInt(range, 10))) &&
          (gender === "all" || dt.gender === gender) &&
          (age === 0 ||
            (age === 18 && dt.age >= age) ||
            (age !== 18 && dt.age <= age)) &&
          (state === 0 || dt.state_id === state) &&
          (district === 0 || dt.district_id === district)
      )
    );
  }, [data, range, eventType, gender, age, state, district]);

  const ageChange = (selectedOption) => {
    setAge(selectedOption.value);
  };

  const genderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  let data_id = 1;

  filteredData.forEach((data) => {
    data.id = data_id++;
  });

  const stateChange = (selectedOption) => {
    console.log("state selectedOption", selectedOption);
    setState(selectedOption.value);
    console.log("state", state);
  };

  const districtChange = (selectedOption) => {
    console.log("district selectedOption", selectedOption);
    setDistrict(selectedOption.value);
    console.log("district", district);
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width custom-pl"
            >
              <Grid item xs={6} sm={6} lg={2}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={eventType}
                  onChange={(e) => setEventType(e.target.value)}
                >
                  <FormControlLabel
                    value="0"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                  <FormControlLabel
                    value="1"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Flight"
                  />
                  <FormControlLabel
                    value="2"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Simulation"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                >
                  <FormControlLabel
                    value="0"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                  <FormControlLabel
                    value="3"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Long Flight"
                  />
                  <FormControlLabel
                    value="2"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Medium Range"
                  />
                  <FormControlLabel
                    value="1"
                    className="radio-button"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: Colors.secondary,
                          },
                        }}
                      />
                    }
                    label="Short Flight"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid container className="mb-15">
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 form-label-ui">
                      Gender
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={genders}
                      onChange={genderChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 mb-5 form-label-ui">
                      Age Less Than
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={ages}
                      onChange={ageChange}
                    />
                    <FormHelperText className="form-note">
                      * Age is taken from 1-Jan of that year.
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Grid container className="mb-15">
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 form-label-ui">
                      State
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={states}
                      onChange={stateChange}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={3} lg={3}>
                    <InputLabel className="mt-10 mb-5 form-label-ui">
                      District
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={9}>
                    <Select
                      className="select-filter"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "50px",
                          boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                          color: "#65698a80",
                          borderColor: "transparent",
                        }),
                      }}
                      options={districts}
                      onChange={districtChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default TopPerformers;
