import Layout from "../components/Layout/Layout";
import DataTable from "../components/DataTable";
import Advertisement from "../components/Advertisement";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { useState, useEffect } from "react";
import { InputLabel } from "@mui/material";
import { getEventsList, getStateandDistrict } from "../services/APIService";

const header = [
  {
    id: "id",
    label: "#",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "start_date",
    label: "Start Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "end_date",
    label: "End Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "total_days",
    label: "No. of Days",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "type",
    label: "Event Type",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "range",
    label: "Range",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "name",
    label: "Event Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "manager_name",
    label: "Manager Name",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "event_id",
    label: "Event ID",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
  {
    id: "code",
    label: "Event Code",
    width: 130,
    numeric: true,
    disablePadding: false,
  },
  {
    id: "last_update_date",
    label: "Last Updated Date",
    width: 130,
    numeric: false,
    disablePadding: false,
  },
];

const Events = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [state, setState] = useState("All");
  const [district, setDistrict] = useState("All");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    getEventsList().then((response) => {
      setData(response.events);
      setFilteredData(response.events);
    });
    getStateandDistrict().then((response) => {
      setDistricts([{ value: "0", label: "All" }, ...response.districts]);
      setStates([{ value: "0", label: "All" }, ...response.states]);
    });
  }, []);

  useEffect(() => {
    setFilteredData(
      state === "0" ? data : data.filter((dt) => dt.state_id === state)
    );
  }, [state]);

  useEffect(() => {
    setFilteredData(
      district === "0" ? data : data.filter((dt) => dt.district_id === district)
    );
  }, [district]);

  let data_id = 1;

  filteredData.forEach((data) => {
    data.id = data_id++;
  });

  const stateChange = (selectedOption) => {
    setFilteredData(data);
    setState(selectedOption.value);
  };

  const districtChange = (selectedOption) => {
    setFilteredData(data);
    setDistrict(selectedOption.value);
  };

  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} display={{ xs: "none", sm: "block" }}>
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width stateDistFilter"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  options={states}
                  onChange={stateChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a80",
                      borderColor: "transparent",
                    }),
                  }}
                  options={districts}
                  onChange={districtChange}
                />
              </Grid>
            </Grid>
            <DataTable header={header} rows={filteredData} pageName="events" />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Events;
