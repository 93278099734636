import { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  getDistrictByStateId,
  getPilotsByStateDistrict,
  getStateandDistrict,
} from "../services/APIService";
import { Box, Grid, InputLabel } from "@mui/material";
import Advertisement from "../components/Advertisement";
import Select from "react-select";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import { useParams } from "react-router-dom";

const Pilots = () => {
  const { state_id } = useParams();
  const { district_id } = useParams();
  const [states, setStates] = useState([{ value: 0, label: "All" }]);
  const [districts, setDistricts] = useState([{ value: 0, label: "All" }]);
  const [state, setState] = useState(state_id);
  const [district, setDistrict] = useState(district_id);
  const [pilots, setPilots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPilots, setTotalPilots] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const selectedDistrict = districts.filter((singleDistrict) =>
    parseInt(district_id) > 0
      ? singleDistrict.value === parseInt(district_id)
      : singleDistrict.value === district
  );
  const selectedState = states.filter((singleState) =>
    parseInt(state_id) > 0 && state === 0
      ? singleState.value === parseInt(state_id)
      : singleState.value === state
  );
  console.log("selectedState", selectedState);
  console.log("state", state);
  const columns = [
    { field: "id", headerName: "#", headerAlign: "center" },
    {
      field: "emp_id",
      headerName: "Emp ID",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "membership_id",
      headerName: "Membership",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "nationality_name",
      headerName: "Nationality",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "state_name",
      headerName: "State ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "district_name",
      headerName: "District ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "f_long_flight",
      headerName: "Long Flight",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
    },
    {
      field: "f_medium_range",
      headerName: "Medium Range",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
    },
    {
      field: "f_short_flight",
      headerName: "Short Flight",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header border-right fw-700 font-size-14 label-color text-center",
    },
    {
      field: "s_long_flight",
      headerName: "Long Flight",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
    },
    {
      field: "s_medium_range",
      headerName: "Medium Range",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
    },
    {
      field: "s_short_flight",
      headerName: "Short Flight",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName:
        "grid-header fw-700 font-size-14 label-color text-center",
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Flight Rating",
      children: [
        { field: "f_long_flight" },
        { field: "f_medium_range" },
        { field: "f_short_flight" },
      ],
      headerAlign: "center",
      headerClassName:
        "grid-sub-header fw-700 font-size-15 label-color text-center border-right border-left",
    },
    {
      groupId: "Simulation Rating",
      children: [
        { field: "s_long_flight" },
        { field: "s_medium_range" },
        { field: "s_short_flight" },
      ],
      headerAlign: "center",
      headerClassName:
        "grid-sub-header fw-700 font-size-15 label-color text-center",
    },
  ];

  useEffect(() => {
    getStateandDistrict().then((response) => {
      setStates([...states, ...response.states]);
      setDistricts([...districts, ...response.districts]);
    });

    getPilotsByStateDistrict(
      state,
      district,
      paginationModel.page + 1,
      paginationModel.pageSize
    ).then((response) => {
      setLoading(true);
      response.pilots ? setPilots(response.pilots) : setPilots([]);
      response.pagination?.total_pilots
        ? setTotalPilots(response.pagination?.total_pilots)
        : setTotalPilots(0);
      setLoading(false);
    });
  }, [state, district, paginationModel.page, state_id, district_id]);

  useEffect(() => {
    if (state > 0) {
      getDistrictByStateId(state).then((response) => {
        setDistricts(response.districts);
        if (!district_id) {
          // console.log('district_id', district_id)
          setDistrict(response.districts[0]["value"]);
        }
      });
    }
    if (state === 0) {
      setDistricts([{ value: 0, label: "All" }]);
      setDistrict(districts[0]["value"]);
    }
  }, [state]);
  // console.log("state", state);
  // console.log("district", district);
  // console.log("districts[0]", districts[0]);
  return (
    <Layout>
      <Box sx={{ width: "95%" }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
          >
            <Advertisement />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid
              container
              spacing={2}
              className="justifyContentBetween custom-select-width custom-pl"
            >
              <Grid item xs={12} sm={4}>
                <InputLabel>State</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a70",
                      borderColor: "transparent",
                    }),
                  }}
                  options={states}
                  onChange={(e) => setState(e.value)}
                  defaultValue={states[0]}
                  value={selectedState}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>District</InputLabel>
                <Select
                  className="select-filter"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "50px",
                      boxShadow: "0px 0px 15px rgba(228, 236, 255, 0.74)",
                      color: "#65698a70",
                      borderColor: "transparent",
                    }),
                  }}
                  options={districts}
                  onChange={(e) => setDistrict(e.value)}
                  defaultValue={districts[0]}
                  value={selectedDistrict}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  width: "100%",
                  height: pilots && pilots.length === 0 ? "" : "",
                }}
              >
                <DataGrid
                  className="dataGridProfile"
                  experimentalFeatures={{ columnGrouping: true }}
                  rows={pilots}
                  columns={columns}
                  components={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  columnGroupingModel={columnGroupingModel}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  rowHeight={52}
                  columnHeaderHeight={50}
                  sx={{
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  }}
                  pagination
                  paginationModel={paginationModel}
                  rowCount={totalPilots}
                  paginationMode="server"
                  onPaginationModelChange={setPaginationModel}
                  loading={loading}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Pilots;
